module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Qatalyst","short_name":"Qatalyst","start_url":"/","background_color":"#ffffff","theme_color":"#ffffff","icon":"src/assets/branding/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"6c48a678ff41c5204013e806c4e19406"},
    },{
      plugin: require('../node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[],"component":"/opt/build/repo/src/layout/index.js"},
    },{
      plugin: require('../node_modules/gatsby-plugin-flexsearch/gatsby-browser.js'),
      options: {"plugins":[],"languages":["en"],"type":"ContentfulPage","fields":[{"name":"title","indexed":true,"resolver":"title","attributes":{"encode":"balance","tokenize":"strict","threshold":6,"depth":3},"store":true},{"name":"id","indexed":false,"resolver":"id","store":true},{"name":"content","indexed":true,"resolver":"content.raw","attributes":{"encode":"balance","tokenize":"strict","threshold":6,"depth":3},"store":true}]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
